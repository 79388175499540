import { createRouter, createWebHistory } from '@ionic/vue-router';
import packs from './packs';
import auth from './auth';
import history from './history';
import movements from './movements';
import ApiToken from '@/utils/auth';

const routes = [...packs, ...auth, ...history, ...movements];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // console.log(localStorage.getItem('API_TOKEN'))
        if (!ApiToken.isSet()) {
            next("/auth/login");
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        if (ApiToken.isSet()) {
            next("/");
        }
    }

    next();
});

export default router
