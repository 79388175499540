import { alertController } from '@ionic/vue';

const defaultOptions = {
    cssClass: 'app-alert',
    header: '',
    message: '',
    buttons: {
        confirm: {
            text: 'Si',
        },
        cancel: {
            text: 'No',
        },
    },
};

export default {
    namespaced: true,
    actions: {
        confirm(context, config) {
            const options = {
                cssClass: config.cssClass || defaultOptions.cssClass,
                header: config.header || defaultOptions.header,
                message: config.message || defaultOptions.message,
                buttons: [
                    {
                        text: config.buttons?.confirm?.text || defaultOptions.buttons.confirm.text,
                        handler: config.handler,
                    },
                    {
                        text: config.buttons?.cancel?.text || defaultOptions.buttons.cancel.text,
                        role: 'cancel',
                    }
                ],
            };

            alertController.create(options).then(alert => alert.present());
        }
    }
}
