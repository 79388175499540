import axios from 'axios';

const api_url = process.env.VUE_APP_API_URL;

/**
 * Se elegió el nombre pack en vez de package por ser una palabra reservada
 */

const store = {
    namespaced: true,
    state: {
        zones: [],
    },
    getters: {
        zones: state => state.zones,
        list: state => state.zones,
    },
    mutations: {
        setZones(state, zones) {
            state.zones = zones;
        },
    },
    actions: {
        index({ commit }){
            axios.get(`${api_url}/zonas`)
                .then(response => {
                    commit('setZones', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        }
    },
};

export default store;