import ApiToken from '../utils/auth';

function apply (instance) {
    instance.interceptors.request.use(config => {
        config.headers['X-TOKEN'] = ApiToken.get();
        return config;
    }, error => {
        return Promise.reject(error);
    });
}

export default apply;
