export default [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('../pages/auth/LoginPage.vue'),
        meta: {
            guest: true,
            requiresAuth: false
        }
    },
    {
        path: '/auth/password',
        name: 'auth.password',
        component: () => import('../pages/auth/AccountPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
];