export default [
    {
        path: '/movements',
        name: 'movements.index',
        component: () => import('../pages/movements/IndexPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/movements/create',
        name: 'movements.create',
        component: () => import('../pages/movements/CreateMovement.vue'),
        meta: {
            requiresAuth: true,
        }
    },
];