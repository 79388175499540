import { alertController } from '@ionic/vue';

export default {
    namespaced: true,
    actions: {
        show(error) {
            const options = {
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: error,
                buttons: ['OK'],
            };

            alertController.create(options).then(alert => alert.present());
        }
    }
}
