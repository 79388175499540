<template>
    <ion-menu side="start" menu-id="app-menu" content-id="content">
        <ion-header>
            <ion-toolbar translucent>
                <ion-title>Menú</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item @click="packages">
                    <ion-label>Paquetes del día</ion-label>
                </ion-item>
                <ion-item @click="buffer">
                    <ion-label>Paquetes pendientes</ion-label>
                </ion-item>
                <ion-item @click="history">
                    <ion-label>Historial de paquetes</ion-label>
                </ion-item>
                <ion-item @click="movements">
                    <ion-label>Cuenta Corriente</ion-label>
                </ion-item>
                <ion-item @click="changePassword">
                    <ion-label>Cambiar contraseña</ion-label>
                </ion-item>
                <ion-item @click="logout">
                    <ion-label>Cerrar sesión</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script>

import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    menuController,
} from "@ionic/vue";

export default {
    components: {
        IonMenu,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
    },

    methods: {
        async openMenu() {
            await menuController.open();
        },
        packages() {
            menuController.close();

            this.$router.push({name: 'packs.index'});
        },
        buffer() {
            menuController.close();

            this.$router.push({name: 'packs.buffer'});
        },
        history() {
            menuController.close();

            this.$router.push({name: 'history.index'});
        },
        movements() {
            menuController.close();

            this.$router.push({name: 'movements.index'});
        },
        changePassword() {
            menuController.close();

            this.$router.push({name:"auth.password"});
        },
        logout() {
            menuController.close();
            
            this.$router.push("/auth/login");

            this.$store.dispatch('auth/logout');
        },
    }
};
</script>
