import axios from 'axios';
import dayjs from 'dayjs';
const api_url = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['X-TOKEN'] = `${localStorage.getItem('API_TOKEN')}`;

const store = {
    namespaced: true,
    state: {
        packs: [],
        loading: false,
        page: 0,
        lastPage: 1000,
        status: 'all',
        period: '30',
    },
    getters: {
        packs: state => state.packs,
        loading: state => state.loading,
        page: state => state.page,
        lastPage: state => state.lastPage,
        status: state => state.status,
        period: state => state.period,
    },
    mutations: {
        setPacks(state, packs) {
            state.packs = packs;
        },
        appendPacks(state, packs) {
            state.packs = state.packs.concat(packs);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setPage(state, page) {
            state.page = page;
        },
        setLastPage(state, lastPage) {
            state.lastPage = lastPage;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setPeriod(state, period) {
            state.period = period;
        },
        reset(state, data) {
            state.page = 0;
            state.lastPage = 1000;
            state.packs = [];
            state.status = data.status || state.status;
            state.period = data.period || state.period;
        }
    },
    actions: {
        index({ commit, state }, payload) {
            commit('setLoading', true);

            const estados = state.status == 'all' ? '20,30,40,50' : state.status;
            const period = state.period == 'all' ? {} : {fecha_desde: dayjs().subtract(state.period, 'days').format('YYYY-MM-DD'), fecha_hasta: dayjs().format('YYYY-MM-DD')};
            
            axios.get(`${api_url}/historicos/paquetes`, {
                    params: {
                        page: state.page + 1,
                        estados,
                        ...period
                    }
                })
                .then(response => {
                    commit('setPage', state.page + 1);
                    commit('setLastPage', response.data.last_page);
                    const mutations = payload?.append ? 'appendPacks' : 'setPacks';
                    commit(mutations, response.data.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        filterChanged({ commit, dispatch }, payload) {
            commit('reset', payload);
            dispatch('index');
        }
    },
};

export default store;