import auth from './authenticate';
import ForbiddenChecker from './ForbiddenChecker';
import laravelValidation from './LaravelValidationManager';

const middlewares = {
    auth,
    ForbiddenChecker,
    laravelValidation
};

export default {
    apply(middleware, instance) {
        if (Object.keys(middlewares).includes(middleware)) {
            middlewares[middleware](instance);

            return this;
        }
    }
};
