import axios from 'axios';

const api_url = process.env.VUE_APP_API_URL;

/**
 * Se elegió el nombre pack en vez de package por ser una palabra reservada
 */

const store = {
    namespaced: true,
    state: {
        municipios: [],
    },
    getters: {
        municipios: state => state.municipios,
        list: state => state.municipios,
    },
    mutations: {
        setMunicipios(state, municipios) {
            state.municipios = municipios;
        },
    },
    actions: {
        index({ commit }){
            axios.get(`${api_url}/municipios`)
                .then(response => {
                    commit('setMunicipios', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
            ;
        }
    },
};

export default store;