import router from '../router';
import ApiToken from '../utils/auth';

function apply (instance) {
    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 403) {
            ApiToken.refresh();
            router.push('/auth/login');
        }

        return Promise.reject(error);
    });
}

export default apply;
