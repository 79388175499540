<template>
  <ion-app>
    <app-menu></app-menu>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import AppMenu from './components/app/AppMenu.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    AppMenu
  }
});
</script>