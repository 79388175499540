import { toastController } from '@ionic/vue';

const defaultOptions = {
    duration: 2000,
    color:"default",
    message:"",
};

export default {
    namespaced: true,
    actions: {
        show(context, config) {
            const options = {
                duration: config.duration || defaultOptions.duration,
                color: config.color || defaultOptions.color,
                message: config.message || defaultOptions.message,
            };

            toastController.create(options).then(toast => toast.present());
        }
    }
}
