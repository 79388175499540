import { createStore } from 'vuex';

// Modules
import auth from './modules/auth';
import packs from './modules/packs';
import history from './modules/history';
import movements from './modules/movements';
import ui from './modules/ui';
import zones from './modules/zones';
import municipios from './modules/municipios';

export default createStore({
    modules: {
        auth,
        packs,
        history,
        movements,
        zones,
        municipios,
        ui,
    },    
    strict: true
});