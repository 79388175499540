export default [
    {
        path: '/',
        redirect: '/packages',
    },
    {
        path: '/packages', // Del día
        name: 'packs.index',
        component: () => import('../pages/packs/IndexPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/pending', // Pendientes - buffer
        name: 'packs.buffer',
        component: () => import('../pages/packs/IndexPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/:id',
        name: 'packs.show',
        component: () => import('../pages/packs/ShowPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/create',
        name: 'packs.create',
        component: () => import('../pages/packs/CreatePage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/:id/ubication',
        name: 'packs.ubication',
        component: () => import('../pages/packs/UbicationPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/:id/edit',
        name: 'packs.edit',
        component: () => import('../pages/packs/EditPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
];