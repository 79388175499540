<template>
    <ion-page>
        <!-- header -->
        <app-header :page-title="pageTitle"></app-header>
        <!-- /header -->

        <!-- content -->
        <ion-content id="content">
            <slot />
        </ion-content>
        <!-- /content -->

        <ion-footer>
            <slot name="app-footer"/>
        </ion-footer>
    </ion-page>
</template>

<script>
import { 
    IonPage, 
    IonContent,
    IonFooter
} from '@ionic/vue';


import AppHeader from '../components/app/AppHeader.vue';

export default {
    components: {
        IonPage,
        IonContent,
        // AppMenu,
        AppHeader ,
        IonFooter
    },

    props: {
        pageTitle: {
            type: String,
            default: ''
        }
    }
}
</script>