import { loadingController } from '@ionic/vue';

export default {
    namespaced: true,
    state: {
        loading: false,
    },
    getters: {
        loading: state => state.loading,
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        show(context, message='Por favor aguarde...') {
            if (context.state.loading) {
                return;
            }
            context.commit('setLoading', true);
            loadingController.create({
                cssClass: 'app-loader',
                message,
            }).then(loading => {
                loading.present();
            });
        },
        dismiss(context) {
            if (!context.state.loading) {
                return;
            }
            context.commit('setLoading', false);
            loadingController.dismiss().then(() => {
                // console.log('Loader closed!', response);
            }).catch(() => {
                // console.log('Error occured : ', err);
            });
        }
    }
}
