import { alertController } from '@ionic/vue';

function apply (instance) {
    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 422) {

            let message = error.response.data.desc;
            if(error.response.data.detalle){
                let first = Object.keys(error.response.data.detalle)[0];
                message = error.response.data.detalle[first][0];
            }

            const options = {
                cssClass: 'my-custom-class',
                header: 'Error',
                buttons: ['OK'],
                message,
            };

            alertController.create(options).then(alert => alert.present());

        }
        return Promise.reject(error);
    });
}

export default apply;
