import loading from './loading';
import alert from './alert';
import errorDisplay from './errorDisplay';
import toast from './toast';

export default {
    namespaced: true,
    modules: {
        loading,
        alert,
        toast,
        errorDisplay,
    }
}