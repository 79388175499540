import axios from 'axios';
import kernel from '../../middlewares';
kernel.apply('auth', axios);
kernel.apply('ForbiddenChecker', axios);
kernel.apply('laravelValidation', axios);

const api_url = process.env.VUE_APP_API_URL;

/**
 * Se elegió el nombre pack en vez de package por ser una palabra reservada
 */

const store = {
    namespaced: true,
    state: {
        packs: [],
        pack: null,
        loading: false,
        error: null,
        ubications: [],
    },
    getters: {
        packs: state => state.packs,
        pack: state => state.pack,
        loading: state => state.loading,
        error: state => state.error,
        ubications: state => state.ubications,
    },
    mutations: {
        setPacks(state, packs) {
            state.packs = packs;
        },
        setPack(state, pack) {
            state.pack = pack;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setError(state, error) {
            state.error = error;
        },
        setUbications(state, ubications) {
            state.ubications = ubications;
        }
    },
    actions: {
        index({ commit }) {
            commit('setLoading', true);

            axios.get(`${api_url}/paquetes/confirmados-hoy`)
                .then(response => {
                    commit('setPacks', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        buffer({ commit }) {
            commit('setLoading', true);

            axios.get(`${api_url}/paquetes/buffer`)
                .then(response => {
                    commit('setPacks', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        show({ commit }, id) {
            axios.get(`${api_url}/historicos/paquetes/${id}`)
                .then(response => {
                    commit('setPack', response.data.paquete);
                    commit('setUbications', response.data.ubicaciones || []);
                }).catch(() => {
                    commit('setUbications', []);
                })
            ;
        },
        create({ commit }, data) {
            commit('setLoading', true);
            axios.post(`${api_url}/paquetes`, data)
                .then(response => {
                    if (response.data.paquete) commit('setPack', response.data.paquete);
                    if(response.data.ubicaciones) commit('setUbications', response.data.ubicaciones);
                }).catch(error => {
                    commit('setError', error.response.data);
                }).finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        store({ commit }, payload){
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${api_url}/paquetes`, payload)
                    .then(response => {
                        commit('setPack', response.data.paquete)
                        if(response.data.ubicaciones) commit('setUbications', response.data.ubicaciones);
                        resolve(response.data.paquete);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            });
            
        },
        storeManual({ commit }, payload){
            commit('setLoading', true);
            
            return new Promise((resolve, reject) => {
                axios.post(`${api_url}/paquetes/crear-manual`, payload)
                    .then(response => {
                        commit('setPack', response.data.paquete);
                        if(response.data.ubicaciones) commit('setUbications', response.data.ubicaciones);
                        resolve(response.data.paquete);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            });
        },
        
        update({ commit }, payload){
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${api_url}/paquetes/${payload.id}`, payload)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            });
        },

        deleteWithConfirmation({ dispatch }, id) {
            return new Promise((resolve, reject) => {
                dispatch("ui/alert/confirm", {
                    header: "Eliminar paquete",
                    message: '¿Está seguro que desea eliminar el paquete?',
                    handler: () => {
                        dispatch("delete",id).then(() => resolve()).catch(error => {
                            reject(error);
                        });
                    },
                },{root:true});
            });
        },
        delete({ commit, state }, id) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.delete(`${api_url}/paquetes/${id}`)
                    .then(() => {
                        if(state.pack)   commit('setPack', null);

                        if(state.packs.length) commit('setPacks', state.packs.filter(pack => pack.id !== id));

                        resolve();
                    }).catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            });
        },
        setUbication({ commit, state }, payload){
            commit('setLoading', true);
            return new Promise((resolve, reject)=> {
                axios.put(`${api_url}/paquetes/${state.pack.id}/guardar-ubicacion`, payload)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            })
        },
        confirmBuffer({ commit }){
            commit('setLoading', true);
            return new Promise((resolve, reject)=> {
                axios.post(`${api_url}/paquetes/confirmar-buffer`)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            })
        },
        searchLocation({ commit }, address){
            commit('setLoading', true);
            return new Promise((resolve, reject)=> {
                axios.get(`${api_url}/location/search/${address}`)
                    .then(response => {
                        commit('setUbications', response.data || []);
                        resolve(response.data || []);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            })
        },
        reset({ commit }) {
            commit('setPack', null);
        },
        clear({ commit }) {
            commit('setPacks', []);
        },
    },
};

export default store;