<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons v-show="isAuthenticated" slot="start">
                <slot name="actions-start">
                    <ion-menu-button auto-hide="false"></ion-menu-button>
                </slot>
            </ion-buttons>
            <ion-title>{{ pageTitle }}</ion-title>
            <ion-buttons slot="end" v-show="showBackButton">
                <slot name="actions-end">
                    <ion-back-button text="Atrás" auto-hide="false"></ion-back-button>
                </slot>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
import {
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonButtons,
    IonMenuButton,
    IonBackButton
} from "@ionic/vue";

import ApiToken from '@/utils/auth';

export default {
    components: {
        IonHeader, 
        IonToolbar, 
        IonTitle, 
        IonButtons,
        IonMenuButton,
        IonBackButton
    },
    props: {
        pageTitle: {
            type: String,
            default: ''
        }
    },
    computed: {
        showBackButton() {
            return this.isAuthenticated && (history.state && history.state.back != "/auth/login");
        },
    },
    setup(){
        return {
            isAuthenticated: ApiToken.isSet()
        }
    },
};
</script>
